import React, { ComponentProps } from 'react'
import { cn } from '@sq/components'

type Props = ComponentProps<'input'>

export default function TextInput({ className, ...props }: Props) {
  return (
    <input
      className={cn(
        'border-sq-gray-300 border',
        'placeholder:text-sq-gray-300 text-sm',
        'flex w-full rounded-md bg-white px-3 py-2',
        'ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium',
        'focus-visible:ring-primary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
        'disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      {...props}
    />
  )
}
